export function postalCodeValidation(postalCode) {
  const unitedStates = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/; // us
  const canadian = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/; // ca

  const universal = /^[A-Z0-9][A-Z0-9\- ]{0,10}[A-Z0-9]$/; // universal

  return unitedStates.test(postalCode) || canadian.test(postalCode) || universal.test(postalCode);
}

export function emailValidation(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
