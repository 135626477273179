<template>
  <div
    class="flex flex-wrap items-baseline w-full overflow-y-scroll justify-items-stretch max-h-28 "
  >

    <div
      v-for="(token, index) in tokens"
      v-show="!input.includes(token)"
      :key="`${token}-${index}`"
      @click="tokenClicked(index)"
      :class="[aboutToBeRemoved(index) || !validToken(index) ? 'bg-red-400' : 'bg-green-700 ']"
      class="flex px-3 py-1 mx-1 mt-1 text-white rounded-lg cursor-pointer"
    >
      {{token}}
      <close-option
        class="cursor-pointer"
        @click.stop="removeElement(index)"
      />
    </div>

    <input
      v-model="input"
      :name="name"
      autocomplete="off"
      @keyup.enter="addToken"
      @keyup.space="addToken"
      @keyup.delete="backSpace"
      class="flex-grow h-10 bg-transparent outline-none focus:outline-none text-content"
    />
  </div>
</template>

<script>
import CloseOption from 'vue-material-design-icons/Close.vue';
import { emailValidation } from '@/utils/validators';

export default {
  name: 'TokenizedInput',
  components: {
    CloseOption,
  },
  props: {
    resultingTokens: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      requried: false,
      validate: (val) => ['text', 'email'].includes(val),
    },
    name: {
      type: String,
      required: false,
      default: 'TokenizedInput',
    },
  },
  data() {
    return {
      tokens: [],
      input: '',
      backSpaceClicks: 0,
      inputEmpty: true,
    };
  },
  watch: {
    tokens(val) {
      console.log(val);
      const validTokens = this.tokens.filter((token, index) => this.validToken(index));
      this.$emit('update:resultingTokens', validTokens);
    },
    input(newVal, oldVal) {
      if (newVal.length > 0) {
        this.backSpaceClicks = 0;
      }

      // Filters out email that was added even if it was not tokenized
      if (!emailValidation(newVal) && oldVal.trim().includes(newVal)) {
        this.tokens = this.tokens.filter((val) => oldVal.trim().split(' ')[0] !== val);
        this.tokens = this.tokens.filter((val) => newVal.trim().split(' ')[0] !== val);
      }

      if (emailValidation(newVal.trim())) {
        // Removes the email if it existed before
        this.tokens = this.tokens.filter((val) => oldVal.trim().split(' ')[0] !== val);

        // Adds the new email if it's valid
        this.tokens.push(
          ...newVal.trim().split(' ').map((val) => val.trim().toLowerCase()),
        );
      }
    },
  },
  methods: {
    addToken() {
      this.backSpaceClicks = 0;
      if (this.input.trim().length > 0) {
        // Removes emails if they existed
        const emailsToAdd = this.input.trim().split(' ').map((val) => val.trim().toLowerCase());
        this.tokens = this.tokens.filter((val) => !emailsToAdd.includes(val));
        // Adds the emails
        console.log(emailsToAdd);
        this.tokens = [...this.tokens, ...emailsToAdd];
        console.log(this.tokens);
        this.input = ' ';
      }
    },
    backSpace() {
      if (this.backSpaceClicks > 1 || !this.validToken(this.tokens.length - 1)) {
        this.tokens.pop();
        this.backSpaceClicks = 0;
      } else if (this.input === '') {
        this.backSpaceClicks += 1;
      }
    },
    removeElement(id) {
      this.tokens.splice(id, 1);
      this.backSpaceClicks = 0;
      this.input = this.input.trim();
      document.querySelector(`input[name="${this.name}"]`).focus();
    },
    aboutToBeRemoved(index) {
      return this.backSpaceClicks > 1 && index === this.tokens.length - 1;
    },
    validToken(index) {
      const token = this.tokens[index];
      if (this.type === 'email') {
        return emailValidation(token);
      }
      return true;
    },
    tokenClicked(index) {
      this.addToken();
      const spliced = this.tokens.splice(index, 1);
      this.input = `${spliced[0]}`;
      document.querySelector(`input[name="${this.name}"]`).focus();
    },
  },
};
</script>
