<template>
  <modal-container
    @cancel="$emit('cancel')"
  >
    <p class="text-xl font-bold text-left text-primary">
      Share an item Category!
    </p>
    <p class="pt-2 font-bold text-left">
      Enter multiple user's email addresss
      and the items will be sent to them!
    </p>
    <div class="flex justify-start w-full pt-4 font-bold">
      <p class="mr-3">Category: </p>
      <select
        v-model="category"
        :input="category"
        class="bg-transparent outline-none text-input focus:outline-none"
      >
        <option >Memory</option>
        <option >Moving</option>
        <option >Storage</option>
        <option >Sale</option>
        <option >Donate</option>
        <option >Junk</option>
      </select>
    </div>
    <div class="flex flex-wrap w-full px-4 bg-backfill rounded-lg">
      <tokenized-input
        type="email"
        :resultingTokens.sync="emails"
      />
    </div>
    <div class="flex flex-row justify-end w-full pt-4">
      <button-large
        @click="$emit('cancel')"
        class="mr-3 w-28 phone:w-36"
        color="red"
      >
        Cancel
      </button-large>
      <button-large
        @click="addUsers"
        :disabled="emails.length === 0"
        class="w-28 phone:w-36"
        color="green"
      >
        Share
      </button-large>
    </div>

  </modal-container>
</template>

<script>
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import TokenizedInput from '@/components/shared/inputs/TokenizedInput.vue';
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';

export default {
  name: 'ProjectSettingsAddUsersModal',
  components: {
    ButtonLarge,
    ModalContainer,
    TokenizedInput,
  },
  data() {
    return {
      input: '',
      emails: [],
      category: 'Memory',
    };
  },
  methods: {
    addUsers() {
      this.$emit(
        'confirm',
        {
          emails: this.emails,
          category: this.category,
        },
      );
    },
  },
};
</script>

<style>

</style>
