<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :show-bottom-nav="true"
      backButtonText="Project"
      :modal-show="modal.shareItems"
      :notificationMessage.sync="notificationMessage"
      :showRightControls="true"
      :loading="loading"
    >
        <template #notification>

        </template>

        <template #modal>
          <items-share-modal
            @cancel="modal.shareItems = false"
            @confirm="shareItems"
          />
        </template>

        <template #right-action-buttons>
          <right-page-action-button
            @click="modal.shareItems = true"
            :active="false"
            title="Share Items"
            icon="share"
          />
        </template>

        <template #title>
          <div class="flex flex-col">
            <p>{{project.name}}</p>
          </div>
        </template>

        <template #content>

          <div
            :class="[toggleFilter ? 'pt-24' : 'pt-14']"
            class="relative flex flex-col items-center w-full pb-4 "
          >

            <div class="`
              absolute top-0 left-0 right-0 flex flex-col items-center mx-auto text-content
            `"
            >
              <div
                class="`
                  flex-grow-0 flex flex-row justify-center items-center
                  flex-shrink-0 pt-2 w-72 md:w-80 lg:w-96 bg-background
                `"
              >
                <search-bar
                  :value.sync="search"
                  placeholder="Search Project Items..."
                />
                <filter-component
                  @click="toggleFilter = !toggleFilter"
                  class="ml-2 cursor-pointer text-content"
                />
              </div>
              <div
                v-if="toggleFilter"
                class="`
                  flex flex-row flex-wrap justify-center pt-4 w-72 md:w-80 lg:w-96 bg-background
                `"
              >

                <div
                  v-for="key in Object.keys(itemTypes)" :key="key"
                  @click="itemTypes[key].selected = !itemTypes[key].selected"
                  class="`
                    flex flex-row items-center px-2 py-1 mx-1 mb-2
                    text-xs rounded-lg border border-contrast text-white cursor-pointer
                  `"
                  :class="
                    [itemTypes[key].selected
                    ? itemTypes[key].selectedColors : itemTypes[key].notSelectedColors]
                  "
                >
                  <moving :size="18" v-if="key === 'Moving'" />
                  <sale :size="18" v-if="key === 'Sale'" />
                  <storage :size="18" v-if="key === 'Storage'" />
                  <junk :size="18" v-if="key === 'Junk'" />
                  <donate :size="18" v-if="key === 'Donate'" />
                  <memory :size="18" v-if="key === 'Memory'" />
                </div>

              </div>
            </div>

              <div
                v-if="projectItems.length === 0"
                class="flex flex-col items-center px-16 mt-16 text-center text-content"
              >
                <p>
                  You currently do not have any items
                </p>
              </div>

            <p
              v-if="projectItems.length > 0 && filteredItems.length === 0"
              class="mt-5 text-content"
            >
              No items match your query
            </p>

            <item-component
              v-for="({id, room_id, name, category, picture}, index) in filteredItems"
              @click="routeToItem({id, roomId: room_id})"
              :key="`item-${index}`"
              :name="name"
              :category="category"
              :image="picture.url"
            />
          </div>

        </template>

        <template #bottom-nav-center>
          <shareIcon
            @click="modal.shareItems = true"
            class="cursor-pointer share"
            :size='37'
          />
        </template>

    </interior-page-content-container>
</template>

<script>
import FilterComponent from 'vue-material-design-icons/Tune.vue';
import Moving from 'vue-material-design-icons/Truck.vue';
import Sale from 'vue-material-design-icons/Upload.vue';
import Storage from 'vue-material-design-icons/InboxArrowDown.vue';
import Junk from 'vue-material-design-icons/TrashCan.vue';
import Donate from 'vue-material-design-icons/TagHeart.vue';
import Memory from 'vue-material-design-icons/Star.vue';
import ShareIcon from 'vue-material-design-icons/Share.vue';

import { mapProjectFields } from '@/store/mappers';
import { PROJECT_GET, PROJECT_ITEMS_GET, PROJECT_SHARE_ITEMS } from '@/store/actions';
import { PROJECT_PERMISSIONS_CURRENT } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import SearchBar from '@/components/shared/inputs/SearchBar.vue';
import ItemComponent from '@/components/projects/ItemComponent.vue';
import ItemsShareModal from '@/components/projects/ItemsShareModal.vue';
import RightPageActionButton from '@/components/shared/inputs/RightPageActionButton.vue';

export default {
  name: 'ProjectItemList',
  components: {
    InteriorPageContentContainer,
    RightPageActionButton,
    SearchBar,
    ItemComponent,
    FilterComponent,
    ItemsShareModal,
    Moving,
    Sale,
    Storage,
    Junk,
    Donate,
    Memory,
    ShareIcon,
  },
  data() {
    return {
      loading: true,
      search: '',
      toggleFilter: false,
      itemTypes: {
        Memory: {
          notSelectedColors: 'text-yellow-400 bg-backfill',
          selectedColors: 'bg-yellow-400',
          selected: false,
          order: 1,
        },
        Moving: {
          notSelectedColors: 'text-blue-600 bg-backfill',
          selectedColors: 'bg-blue-600',
          selected: false,
          order: 2,
        },
        Storage: {
          notSelectedColors: 'text-pink-600 bg-backfill',
          selectedColors: 'bg-pink-600',
          selected: false,
          order: 3,
        },
        Sale: {
          notSelectedColors: 'text-green-600 bg-backfill',
          selectedColors: 'bg-green-600',
          selected: false,
          order: 4,
        },
        Donate: {
          notSelectedColors: 'text-purple-600 bg-backfill',
          selectedColors: 'bg-purple-600',
          selected: false,
          order: 5,
        },
        Junk: {
          notSelectedColors: 'text-red-600 bg-backfill',
          selectedColors: 'bg-red-600',
          selected: false,
          order: 6,
        },

      },
      notificationMessage: false,
      modal: {
        shareItems: false,
      },
    };
  },
  computed: {
    ...mapProjectFields(['project', 'projectItems']),
    projectPermissions() {
      return this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
    },
    categoryFiltersActive() {
      return Object.keys(this.itemTypes)
        .filter((key) => this.itemTypes[key].selected).length > 0;
    },
    filteredItems() {
      const items = this.projectItems.filter((item) => {
        const filterBasedOnCategory = this.categoryFiltersActive
          && this.toggleFilter
          && !this.itemTypes[item.category].selected;
        if (filterBasedOnCategory) return false;
        return item.name.toLowerCase().includes(this.search.toLowerCase());
      });

      return items.sort(
        (a, b) => this.itemTypes[a.category].order - this.itemTypes[b.category].order,
      );
    },
  },
  async created() {
    await this.$store.dispatch(PROJECT_GET, this.$route.params.project_id)
      .catch(() => this.$router.push({
        name: 'SpecificPageNotFound',
        params: { page: 'project' },
      }));

    await this.$store.dispatch(PROJECT_ITEMS_GET, this.$route.params.project_id)
      .catch(() => this.$router.push({
        name: 'SpecificPageNotFound',
        params: { page: 'project' },
      }));

    this.loading = false;
  },
  methods: {
    backNav() { this.$router.go(-1); },
    createItem() {
      this.$router.push({
        name: 'ItemCreate',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
        },
      });
    },
    routeToItem({ id, roomId }) {
      this.$router.push({
        name: 'Item',
        params: {
          project_id: this.$route.params.project_id,
          room_id: roomId,
          item_id: id,
        },
      });
    },
    shareItems({ emails, category }) {
      this.modal.shareItems = false;
      // TODO add in a notification telling the user the item has been shared!
      this.notificationMessage = 'Items have been shared!';
      this.$store.dispatch(PROJECT_SHARE_ITEMS, {
        projectId: this.$route.params.project_id,
        emails,
        category,
      });
    },
  },
};
</script>
