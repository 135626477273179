<template>
  <div
    class="z-20 flex flex-col items-center justify-center w-full h-full bg-film modal"
  >
    <div class="`
      relative flex flex-col items-start justify-center
      px-7 rounded-lg h-96 bg-background text-content phone:p-14 w-11/12 lg:w-2/3 py-14
      shadow-xl
    `">
      <close-option
        @click="$emit('cancel')"
        class="absolute flex flex-col cursor-pointer left-4 top-4 text-content close-modal"
      />

      <slot />
    </div>
  </div>
</template>

<script>
import CloseOption from 'vue-material-design-icons/Close.vue';

export default {
  name: 'ModalContainer',
  components: {
    CloseOption,
  },
};
</script>

<style>

</style>
